<template>
  <Teleport to="body" :disabled="mdAndLarger">
    <div
      class="layouts-navbar-wrap relative z-110"
      :data-is-open="isSideBarMenuOpen"
    >
      <div class="layouts-navbar-block" />

      <div class="layouts-navbar" :data-is-open="isSideBarMenuOpen">
        <div
          class="relative flex flex-col w-full h-[100vh] navbar-main px-12px"
        >
          <div
            class="flex flex-row items-center justify-center w-full show-logo flex-nowrap py-22px cursor-pointer"
          >
            <div
              v-show="isSideBarMenuOpen"
              class="flex items-center justify-center w-full max-w-176px"
              :class="{
                'max-w-0': !isSideBarMenuOpen,
              }"
              @click="navigateTo({ name: 'Home' })"
            >
              <CommonAsset
                class="show-logo-asset w-full"
                name="images-logo-logo"
              />
            </div>
            <div
              class="relative flex items-center justify-center transform rotate-0 cursor-pointer show-logo-expand min-w-36px h-28px rounded-16px"
              :class="{
                'rotate-180 <md:hidden': !isSideBarMenuOpen,
                [isSideBarMenuOpen
                  ? '<md:-right-27px -right-30px'
                  : 'right-0']: true,
              }"
              @click="modifyLock"
            >
              <CommonAsset name="icons-expand" class="w-14px" />
            </div>
          </div>
          <div class="relative flex-1 menu-main">
            <ul
              class="absolute top-0 left-0 w-full h-full overflow-y-scroll overflow-x-hidden"
              :class="useHideScrollbar"
            >
              <template v-for="item in menuList" :key="item.label">
                <li @click="pageTo(item)">
                  <LayoutsMenuItem
                    v-if="!item.extraBottom"
                    :is-open="isSideBarMenuOpen"
                    :route-name="item.name"
                    :icon-name="item.icon"
                    :is-active="item.isActive"
                  >
                    <p
                      v-show="isSideBarMenuOpen"
                      class="overflow-hidden menu-label"
                    >
                      {{ item.label }}
                    </p>
                    <div v-if="item.name === 'Promotions'">
                      <CommonAsset
                        name="icons-menu-coin"
                        class="h-auto promotion-coin-icon"
                      />
                    </div>
                    <div
                      v-if="
                        item.recommendedStatus && item.recommendedStatus !== 0
                      "
                    >
                      <CommonAsset
                        class="h-auto"
                        :class="{
                          'w-[18px]': item.recommendedStatus === 2,
                          'w-[30px]': item.recommendedStatus === 1,
                        }"
                        :name="
                          item.recommendedStatus === 1
                            ? 'icons-menu-new'
                            : 'icons-menu-hot'
                        "
                      />
                    </div>
                  </LayoutsMenuItem>
                  <p v-if="item.extraBottom" class="line my-24px"></p>
                </li>
              </template>
              <!-- <client-only>
                <DownloadQr v-if="isSideBarMenuOpen" />
              </client-only> -->
            </ul>
          </div>
        </div>
        <div
          v-show="isSideBarMenuOpen"
          class="fixed -z-1 w-full h-[100vh] top-0 left-0 fixed-shadow hidden <lg:flex hide-scrollbar-mobile"
          @click.stop="modifyLock"
        ></div>
        <Teleport to="body">
          <ul
            class="tab-bar fixed z-100 w-full hidden <md:grid <md:px-0 @sm:px-20px grid-cols-5 top-auto left-0 bottom-0 right-0 hide-scrollbar-mobile"
            :class="{ '-left-full': !context.isMobileBottomNavBarShow }"
          >
            <li
              v-for="item in TabBarList"
              :key="`TabBarItem-${item.key}`"
              class="relative h-full pt-3 cursor-pointer tab-bar-item select-none"
              :class="{
                active: routeName === item.key,
                deposit: item.key === 'Promotions',
              }"
              @click="tabClick(item)"
            >
              <div
                v-if="item.key === 'Promotions'"
                class="flex flex-col items-center"
              >
                <span
                  class="deposit-outline-bg-circle absolute -top-25px p-10px rounded-1 <sm:-top-18px"
                >
                  <CommonAsset
                    :name="item.icon"
                    class="deposit-bg-circle rounded-1 w-50px p-10px <sm:w-[40px] <sm:p-[5px]"
                  />
                  <div
                    class="promotions-circle flex items-center justify-center absolute top-0 right-0 bg-red text-white w-5 h-5 rounded-full font-medium"
                  >
                    1
                  </div>
                </span>
                <span class="deposit-text text-12px mt-31px z-1 font-600">{{
                  item.label
                }}</span>
              </div>
              <div v-else class="flex flex-col items-center">
                <CommonAsset
                  :name="
                    routeName === item.key ? `${item.icon}-active` : item.icon
                  "
                />
                <span class="text-12px mt-7px font-400">{{ item.label }}</span>
              </div>
            </li>
          </ul>
          <div
            v-if="
              context.isDeviceHorizontal && !context.isMobileBottomNavBarShow
            "
            class="fixed -left-10px bottom-10px z-10"
            @click="
              update({
                isMobileBottomNavBarShow: true,
              })
            "
          >
            <CommonAsset
              name="icons-mobile-bottom-open"
              class="duration-1000 opacity-[0.6]"
            />
          </div>
        </Teleport>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import type { RouteLocationNamedRaw } from "vue-router"
import { breakpointsTailwind } from "@vueuse/core"
import { useUserStore } from "~/stores/user"
import { isMobileequipment } from "~/utils/validate"
import { useHomeStore } from "~/stores/home"
import { useUnLogin } from "~/composables/unLogin"
import { useGame } from "~/stores/game"
import { useComponentsContext } from "~/composables/useComponentsContext"
import { useDialog } from "~/composables/useDialog"
import { useHideScrollbar } from "~/composables/useHideScrollbar"
// import DownloadQr from "~/components/app-download/DownloadQr.vue"
import { __is_sidebar_open } from "#imports"

const route = useRoute()
const router = useRouter()
const routeName = computed(() => route.name)
const user = useUserStore()
const { setValue } = useStorage()
const isSideBarMenuOpen = ref<boolean>(false)
const { handleUnLogin } = useUnLogin()
const game = useGame()
const endingInfo = computed(
  () => game.endingInfo as SpaceGameRelated.SlotGameList.GameItem
)
const { context, update } = useComponentsContext()

const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndLarger = breakpoints.greaterOrEqual("lg")
const mdAndLarger = breakpoints.greaterOrEqual("md")
const { t } = useI18n()

const watherLgAndLarger = watch(lgAndLarger, () => {
  if (lgAndLarger.value) isSideBarMenuOpen.value = true
  else isSideBarMenuOpen.value = false
})

const watherIsSideBarMenuOpen = watch(
  isSideBarMenuOpen,
  (val) => {
    user.$patch({
      isNavBarOpne: val,
    })
  },
  {
    immediate: true,
  }
)

const isLogin = computed(() => user.isLogin)

const home = useHomeStore()
interface ListOptions {
  label?: string
  icon?: string
  name?: any
  extraBottom?: boolean
  options?: Omit<RouteLocationNamedRaw, "name">
  auth?: boolean
  isActive?: () => boolean
  path?: string
  key?: string
  recommendedStatus?: number
}

const menuList = computed<ListOptions[]>(() => {
  const gameCategoriesMenu = gameCategoriesMenuState.gameCategoriesMenu.map(
    (item) => {
      return {
        label: item.lable,
        icon: item.icon,
        name: item.routeName,
        recommendedStatus: item.recommendedStatus,
      }
    }
  )

  return [
    {
      label: t('menu.favorite'),
      icon: "icons-sliderbar-favorites",
      name: "Favorites",
      auth: true,
    },
    {
      label: t('menu.promotion'),
      icon: "icons-sliderbar-gift-yellow",
      name: "Promotions",
      isActive() {
        return router.currentRoute.value.name === "PromotionInfo"
      },
    },
    // {
    //   label: "About Us",
    //   icon: "icons-sliderbar-about-us",
    //   name: "AboutUs",
    //   recommendedStatus: 1,
    // },
    {
      label: t('menu.dailyTask'),
      icon: "icons-sliderbar-daily-tasks",
      name: "RaffleTicket",
      recommendedStatus: 1,
      auth: true,
    },
    {
      label: t('menu.staking'),
      icon: "icons-sliderbar-staking",
      name: "Staking",
      auth: true,
    },
    { extraBottom: true },
    { label: t('tabs.home'), icon: "icons-sliderbar-home", name: "Home" },
    ...gameCategoriesMenu,
    { extraBottom: true },
    // 以後可能還會打開 先註解
    // {
    //   label: "Affiliate",
    //   icon: "icons-sliderbar-affiliate",
    //   name: "Affiliate-ID",
    //   options: {
    //     params: {
    //       id: `${user.info?.id ?? 0}`,
    //     },
    //   },
    //   isActive() {
    //     const condition = [
    //       router.currentRoute.value.name === "Affiliate-ID",
    //       router.currentRoute.value.name === "Affiliate-Rule",
    //     ]
    //     return condition.some((c) => c)
    //   },
    // },

    {
      label: t('footer.help'),
      icon: "icons-sliderbar-help-center",
      name: "HelpCenter",
    },{
      label: t('menu.club'),
      icon: "icons-sliderbar-vipLogo",
      name: "VIPClub",
    },
  ]
})
const TabBarList = [
  { label: "Menu", icon: "icons-tab-menu", key: "menu" },
  {
    label: "Home",
    icon: "icons-tab-home",
    key: "Home",
  },
  {
    label: "Promotions",
    icon: "icons-sliderbar-gift-yellow",
    path: "promotions",
    key: "Promotions",
  },
  {
    label: "Deposit",
    icon: "icons-tab-deposit",
    path: "deposit",
    key: "Deposit",
  },
  {
    label: "Profile",
    icon: "icons-tab-profile",
    path: "my",
    key: "My",
  },
].map((item) => {
  return {
    name: item.key === "My" ? "Wallet" : item.key,
    ...item,
  }
})

const isMenuItemDisabled = (item: ListOptions) => {
  return item.auth && !isLogin.value
}

const pageTo = async (row: ListOptions) => {
  if (isMenuItemDisabled(row)) {
    useDialog().openDialog("PwdSign", row.name)
    return
  }
  if (routeName.value === row.name) return
  
  await navigateTo({ name: row.name as RouteName, ...row.options })
}
const tabClick = ({ key }: ListOptions) => {
  switch (key) {
    case "menu":
      isSideBarMenuOpen.value = !isSideBarMenuOpen.value
      setValue(__is_sidebar_open, isSideBarMenuOpen.value ? "1" : "0")
      if (context.value.isDeviceHorizontal)
        update({ isMobileBottomNavBarShow: false })
      break
    case "My":
    case "Deposit":
      if (isLogin.value) {
        navigateTo({ name: key as RouteName })
      } else {
        useDialog().openDialog("PwdSign")
      }
      break
    default:
      isSideBarMenuOpen.value = false
      setValue(__is_sidebar_open, "0")
      navigateTo({ name: key as RouteName })
      break
  }
}

const modifyLock = () => {
  isSideBarMenuOpen.value = !isSideBarMenuOpen.value
  setValue(__is_sidebar_open, isSideBarMenuOpen.value ? "1" : "0")
  modifyVisible()
}
const modifyVisible = async () => {
  // 完成动画时间需要300
  await delay(300)
  home.MODIFY_VISIBLE(!isSideBarMenuOpen.value)
}

const isEndingOpen = computed(() => endingInfo.value.status === 1)
const goEndingGame = async () => {
  if (await handleUnLogin()) return
  const { id, gameProviderSubtypeId, gameProviderId } = endingInfo.value
  await navigateTo({
    name: "GameRoom",
    query: { gameProviderSubtypeId, gameId: id, gameProviderId },
  })
}

const onOrientationChange = () => {
  if (window.orientation === 90 || window.orientation === -90) {
    update({ isDeviceHorizontal: true, isMobileBottomNavBarShow: false })
    document.addEventListener("touchmove", closeMobileBootomNavBar)
  } else if (window.orientation === 0) {
    update({ isDeviceHorizontal: false, isMobileBottomNavBarShow: true })
    document.removeEventListener("touchmove", closeMobileBootomNavBar)
  }
}

const closeMobileBootomNavBar = () => {
  update({ isMobileBottomNavBarShow: false })
}

onMounted(() => {
  if (!isMobileequipment()) {
    isSideBarMenuOpen.value = true
    setValue(__is_sidebar_open, "1")
  } else {
    setValue(__is_sidebar_open, "0")
  }
  if (isMobileequipment()) {
    onOrientationChange()
    window.addEventListener("orientationchange", onOrientationChange)
  }
  modifyVisible()
})

onUnmounted(() => {
  if (isMobileequipment()) {
    try {
      window.removeEventListener("orientationchange", onOrientationChange)
    } catch (error) {}
  }
  watherLgAndLarger()
  watherIsSideBarMenuOpen()
})
</script>

<style lang="scss" scoped>
@import "~/assets/sass/mixins.scss";
.promotions-circle {
  transform: translate(-1px, 2px);
}
.promotion-coin-icon {
  width: 20px;
  transform: scale(3.5) !important;
}
.layouts-navbar-wrap {
  z-index: 110;
  --side-bar-width: 70px;
  &[data-is-open="true"] {
    --side-bar-width: 200px;
  }
}
.layouts-navbar-block {
  transition: width 0.3s;
  @apply w-$side-bar-width;
  @screen <md {
    display: none;
  }
}
.layouts-navbar {
  transition: width 0.3s;
  background: var(--v-navbar-bg);
  @apply z-20 w-full h-full;
  @apply max-w-$side-bar-width fixed;
  @screen <md {
    @apply z-100 max-w-0 fixed;
  }

  &[data-is-open="true"] {
    @apply max-w-$side-bar-width !important;
  }
  &[data-is-open="false"] {
    @screen <md {
      .navbar-main {
        @apply <md:px-0 !important;
      }
    }
  }
  .navbar-main {
    background: var(--v-navbar-bg);
    .show-logo {
      .show-logo-expand {
        background: var(--v-nav-expand-bg);
        @screen <md {
          display: none;
        }
      }
      .show-logo-asset {
        transform: translateX(8%);
        @screen <md {
          transform: unset;
        }
      }
    }
    .menu-label {
      white-space: nowrap;
      transition: width 0.3s;
    }
    .line {
      height: 1px;
      background: var(--v-navbar-extra-b);
    }
  }
  .fixed-shadow {
    background: rgba(0, 8, 20, 0.4);
    backdrop-filter: blur(2px);
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.tab-bar {
  transition: left 1s, padding 0.2s;
  background: var(--v-tab-bar-bg);
  box-shadow: 0px -4px 4px rgba(11, 31, 48, 0.2);
  min-height: 60px;
  padding-bottom: env(safe-area-inset-bottom, 0);
  .tab-bar-item {
    background: none;
    transition: opacity 0.2s;
    opacity: 0.6;
    &.active {
      opacity: 1;
      cursor: default;
    }
    &:hover {
      opacity: 1;
    }
    &.deposit {
      opacity: 1;
    }

    &.deposit.active {
      cursor: default;
    }
  }
}
</style>
<style lang="scss">
@import "~/assets/sass/mixins.scss";
.deposit-outline-bg-circle {
  .deposit-bg-circle {
    @include background-border(
      linear-gradient(#1680df 0%, #0e49b7 100%),
      linear-gradient(180deg, #83c3ff 0%, #1050bd 100%),
      1px
    );
  }
}
</style>
