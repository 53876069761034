<template>
  <a
    :href="itemRoutePath"
    rel="tag"
    referrerpolicy="same-origin"
    :class="{ 'cursor-default': disabled }"
    @click.prevent
  >
    <CommonButton2
      :id="`id-menu-item-${props.routeName}`"
      :aria-label="`aria-label-menu-item-${props.routeName}`"
      :disabled="disabled"
      :is-active="isActive"
      :svg-icon-name="props.iconName"
      :is-label-open="props.isOpen"
      :is-recommended-status="true"
      class="menu-item w-full leading-snug !justify-start max-h-[48px]"
      :class="{
        'w-auto': !props.isOpen,
      }"
    >
      <template #status>
        <slot />
      </template>
    </CommonButton2>
  </a>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    routeName?: RouteName
    iconName?: string
    isOpen?: Boolean
    disabled?: boolean
    isActive?(): boolean
  }>(),
  {
    routeName() {
      return "404"
    },
    iconName() {
      return ""
    },
    isOpen() {
      return false
    },
    disabled() {
      return false
    },
    isActive() {
      return false
    },
  }
)

const route = useRoute()
const isActive = computed(
  () => props.isActive() || props.routeName === route.name
)
const itemRoutePath = computed(() => RouteRecord[props.routeName].path ?? "")
</script>
<style lang="scss" scoped>
.menu-item {
  font-family: "Rubik";
  font-weight: 600;
}
</style>
